<div class="principalContainer row">
    <div class="d-flex flex-row-reverse">
        <i class="bi bi-x-lg" style="color: #ffa519; cursor: pointer;" (click)="close()"></i>
    </div>
    <div class="row">
        <div class="leftContainer d-flex col-md-6">
            <div class="profile">{{'MyAccount.my_profile' | translate}}</div>
            <div class="profileText">{{'MyAccount.profile_text' | translate}}</div>
            <div class="profileText" id="changePasswordText" (click)="toggleFomrsFunction()">
                <u class="account-option">{{'MyAccount.change_password' | translate}}</u>
            </div>
            <div class="profileText" id="changePasswordText">
                <u class="account-option" id="closeButton" (click)="back()">{{'MyAccount.personal_data' | translate}}</u>
            </div>

            <div class="row" *ngIf="this.isCountryAdmin">
                <span class="labelInputs">{{'MyAccount.authorized_countries' | translate}}</span>
                <div class="countries">
                    <span *ngFor="let country of getCountryNameByLanguage(userCountries)">
                        {{ country }}
                    </span>
                </div>
            </div>
        </div>

        <div class="rightContainer d-flex col-md-6">
            <div [formGroup]="userForm" *ngIf="toggleForms == false">
                <div class="row">
                    <label class="labelInputs" for="email">{{'MyAccount.email' | translate}}</label>
                    <input type="text" formControlName="email" type="text" name="email" id="email" class="inputSize customInput" readonly>
                </div>

                <div class="row">
                    <label class="labelInputs" for="userName">{{'MyAccount.name' | translate}}</label>
                    <input type="text" formControlName="userName" type="text" name="userName" id="userName" class="inputSize customInput">
                </div>

                <div class="row" *ngIf="!this.isCountryAdmin">
                    <label class="labelInputs" for="adress">{{'MyAccount.address' | translate}}</label>
                    <input type="text" formControlName="adress" type="text" name="adress" id="adress" class="inputSize customInput">
                </div>

                <div class="row">
                    <label class="labelInputs" for="phoneNumber">{{'MyAccount.phone_number' | translate}}</label>
                    <div class="col-3 phoneIndicativePadding">
                        <mat-form-field appearance="fill" class="phoneIndicative">
                            <mat-label>{{'MyAccount.phone_indicative' | translate}}</mat-label>
                            <mat-select formControlName="phoneIndicative">
                                <mat-option value="+34">
                                    +34
                                </mat-option>
                                <mat-option value="+57">
                                    +57
                                </mat-option>
                                <mat-option value="+81">
                                    +81
                                </mat-option>
                                <mat-option value="+1">
                                    +1
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-9">
                        <input type="text" formControlName="phoneNumber" type="number" name="phoneNumber" id="phoneNumber" class="inputSize customInput">
                    </div>
                </div>

                <div class="row">
                    <div class="buttonContainer col">
                        <button id="addButton" type="button" (click)="onSubmit()">{{'MyAccount.save' | translate}}</button>
                        <button id="closeButton" (click)="close()">{{'MyAccount.close' | translate}}</button>
                    </div>
                </div>
            </div>

            <!-- Change password form -->
            <div [formGroup]="changePasswordUser" *ngIf="toggleForms == true">
                <div class="row passwordContainer">
                    <label for="newPassWord">{{'MyAccount.change_password' | translate}}</label>
                    <input type="text" formControlName="newPassWord" type="password" name="newPassWord" id="newPassWord" #passwordInput class="inputSize customInput">

                    <!-- fields for connfrim password -->
                    <label for="confirmPassword">{{'MyAccount.confirm_password' | translate}}</label>
                    <input type="text" formControlName="confirmPassword" type="password" name="confirmPassword" id="newPassWordConfirm" #passwordConfirmInput class="inputSize customInput">
                    <div class="viewPassword" (click)="togglePasswordVisibility()"></div>
                </div>

                <div class="row">
                    <div class="buttonContainer col">
                        <button id="addButton" type="button" (click)="onSubmitPassword()" [disabled]="changePasswordUser.invalid">{{'MyAccount.save' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>