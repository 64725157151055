import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { PageLoadingService } from './page-loading.service';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private sessionExpired = false;

  constructor(
    private _snackBar: MatSnackBar, 
    private http: HttpClient, 
    private _translateService: TranslateService,
    private _pageLoadingService: PageLoadingService
  ) {}

  openStandardSnackBar(message: string, action: string = 'x', duration: number = 5000) {
    if (this.sessionExpired && message !== 'session_expired') return; // Bloquear otros mensajes SOLO si no es el de sesión expirada

    const selectedLanguage = this._translateService.currentLang;
    this.http.get<any>(`assets/i18n/${selectedLanguage}.json`).subscribe(
      responseMessages => {
        let errorMessage = responseMessages.popUpMessages[message] || 
                           responseMessages.popUpMessages.defaultErrorMessage || 
                           'Error desconocido.';

        const panelClass = message === 'session_expired' 
          ? 'snack-bar-session-expired' 
          : action.toLowerCase() === 'ok' 
            ? 'snack-bar-panel-ok' 
            : 'snack-bar-panel';

        this._snackBar.open(errorMessage, action, { duration, panelClass: [panelClass] });
        this._pageLoadingService.hideLoadingGif();
      },
      error => {
        console.error(`Error al cargar los mensajes de error: ${error}`);
        this._pageLoadingService.hideLoadingGif();
      }
    );
  }

  openSessionExpiredSnackBar() {
    this.sessionExpired = true; // Bloquea otros mensajes
    this.openStandardSnackBar('session_expired', 'OK', 5000);
    this._pageLoadingService.hideLoadingGif();


    // Después de 5 segundos, permite que otros mensajes se muestren de nuevo
    setTimeout(() => {
      this.sessionExpired = false;
      this._pageLoadingService.hideLoadingGif();
    }, 5000);
  }
}
