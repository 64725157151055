import { Routes } from "@angular/router";
import { LoggedUser } from "./user.auth";

interface RoutePermission {
  route: string;
  permission: string;
}

interface RoleRoute {
  role: string;
  defaultRoute: string[];
}

const defaultRoutesForRole: RoleRoute[] = [
  {
    role: 'no_logged',
    defaultRoute: ['/book/shop']
  },
  {
    role:'pencil_owner',
    defaultRoute: ['/book/shop']
  },
  {
    role:'editorial_user',
    defaultRoute: ['/book/shop']
  },
  {
    role:'editorial_admin',
    defaultRoute: ['/users/list']
  },
  {
    role:'country_admin',
    defaultRoute: ['/users/list']
  }
];

class ValidateRoutePermissions {

  private static _routesPermissions: RoutePermission[] = [];

  constructor() { }

  static registerRoutesAndPermissions(routes: Routes) {
    routes.forEach(r => {
      r.children?.forEach(c => {
        if (c.data !== null && c.data !== undefined && c.data.hasOwnProperty('permission')) {
          ValidateRoutePermissions._routesPermissions.push({ route: `/${r.path}/${c.path}`, permission: c.data['permission'] });
        }
      });
    });
  }

  static validatePermission(url: string, permission: string[]) {
    let urlInArray = url.split('/');
    let routePermissionArray = [];
    let areEquals = false;

    return ValidateRoutePermissions._routesPermissions.some(p => {
      routePermissionArray = p.route.split('/');

      if (urlInArray.length == routePermissionArray.length) {
        for (let i = 0; i < routePermissionArray.length; i++) {
          if (routePermissionArray[i] == urlInArray[i]) {
            areEquals = true;
          } else if (routePermissionArray[i].includes(':')) {
            areEquals = true;
          } else {
            areEquals = false;
          }
        }
      }

      return areEquals &&
        (permission.includes(p.permission) || p.permission == 'all')

    });
  }

  static getDefaultUrlForUser(user: LoggedUser | null) {
    if(user !== null && user !== undefined) {
      return defaultRoutesForRole.find(r => user.roles.includes(r.role))?.defaultRoute;
    }

    return defaultRoutesForRole.find(r => r.role == 'no_logged')?.defaultRoute;

  }

}

export { RoutePermission, ValidateRoutePermissions, defaultRoutesForRole };
