import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { environment as env } from 'src/environments/environment';
import { LoginService } from '../../services/logIn.service';
// Web\frontend\src\app\auth\services\logIn.service.ts
import { UserService } from 'src/app/users/services/user.service';
// Web\frontend\src\app\users\services\user.service.ts
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { log } from 'console';
import { ActivatedRoute } from '@angular/router';
import { CountryService } from 'src/app/countries/services/country.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {

  updatePasswordForm: FormGroup;
  email: string = "";
  token: string = "";
  password: string = "";
  confirmPassword: string = "";
  countryName: string = '';
  showErrorMessages = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private fb: FormBuilder,
    private _userService: UserService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private _countryService: CountryService,
    private cdr: ChangeDetectorRef,
  ) { 
    this.updatePasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Obtener el valor del token de la URL
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });

    // Obtener el valor del parámetro email de la URL
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
    });

    const storedCountryName = localStorage.getItem('countryName');
    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    } else {
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    }
  }

  private readonly updatePasswordObserver = {
    next: (data: CommonResponse<any>) => this.updatePasswordNext(data),
    error: (error: CommonResponse<any>) => this.updatePasswordError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  updatePasswordNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      this._snackbarService.openStandardSnackBar('password_change', 'ok');
      setTimeout(() => {
        window.location.href = '#/login';
      }, 4000);
    }else{
      this._snackbarService.openStandardSnackBar('password_no_change');
    }

    this._pageLoadingService.hideLoadingGif();
  }

  updatePasswordError(error: CommonResponse<any>) {
    try {
      if(error.errors[0] == 'expirated_token') {
        this._snackbarService.openStandardSnackBar('expirated_token');
        setTimeout(() => {
          window.location.href = '#/users/recover-password';
        }, 4000);
      }
    } catch (error) {
      this._snackbarService.openStandardSnackBar('password_no_change');
    }
    // if(error.errors[0] == 'expirated_token') {
    //   this._snackbarService.openStandardSnackBar('expirated_token');
    //   setTimeout(() => {
    //     window.location.href = '#/users/recover-password';
    //   }, 4000);
    // } else {
    //   this._snackbarService.openStandardSnackBar('password_no_change');
    // }
    this._pageLoadingService.hideLoadingGif();
  }

  isInvalidPassword() {
    return this.password.length < env.password_min_length;
  }
  isInvalidConfirmPassword() {
    return this.confirmPassword.length < env.password_min_length;
  }

  sendNewPassword() {
    if( !this.isInvalidPassword() && !this.isInvalidConfirmPassword() ) {
      if (this.password === this.confirmPassword){ // password coinciden
        this._pageLoadingService.showLoadingGif();
  
        let response: Observable<CommonResponse<any>> = this._userService.updatePasswordByUser(this.password, this.email, this.token);
        response.subscribe(this.updatePasswordObserver);
      } else {
        this._snackbarService.openStandardSnackBar('passwords_no_coincide')
      }
    } else {
      this.showErrorMessages = true;
    }
   
  }

}
