import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import { GeneralPaymentService } from '../../services/general-payment.service';
import { CountryService } from 'src/app/countries/services/country.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { log } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { PersonType } from 'src/app/books/models/person-type.model';
import { GlobalCountry } from 'src/app/books/models/global-country.model';
import { GlobalState } from 'src/app/books/models/global-state.model';
import { GlobalCity } from 'src/app/books/models/global-city.model';
import { IdentificactionType } from 'src/app/books/models/identification-type.model';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss']
})
export class ShippingFormComponent implements OnInit, OnDestroy {
  private langChangeSubscription: Subscription;
  billingForm: FormGroup;
  shippingForm: FormGroup;
  country: string = '';
  isShippingFormDisabled = true;
  isProcessingPayment: boolean = false;
  userId: number;
  orderPayment: any;
  private formSubscription: Subscription | null = null;
  // countries: GlobalCountry[] = [];

  countriesGlobal: GlobalCountry[] = [];
  statesGlobal: GlobalState[] = [];
  citiesGlobal: GlobalCity[] = [];

  countriesGlobalShipping: GlobalCountry[] = [];
  statesGlobalShipping: GlobalState[] = [];
  citiesGlobalShipping: GlobalCity[] = [];

  countryCode: string = "esp";
  currentLang: string;
  languageCode: string;
  personTypes: PersonType[] = [];
  personTypesShipping: PersonType[] = [];
  identificationTypes: IdentificactionType[] = [];
  identificationTypesShipping: IdentificactionType[] = [];
  checkCopyData: boolean = false;
  languageList = [
    { currentLang: 'en', languageCode: 'eng' },
    { currentLang: 'es', languageCode: 'spa' },
    { currentLang: 'pt', languageCode: 'por' },
  ];

  // readonly getCountriesObserver = {
  //   next: (data: CommonResponse<any>) => this.getCountriesNext(data),
  //   error: (error: CommonResponse<any>) => this.getCountriesError(error),
  //   complete: () => this._pageLoadingService.hideLoadingGif()
  // };

  readonly getPersonTypesObserver = {
    next: (data: CommonResponse<any>) => this.getPersonTypesNext(data),
    error: (error: CommonResponse<any>) => this.getPersonTypesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getIdentificationTypesObserver = {
    next: (data: CommonResponse<any>) => this.getIdentificationTypesNext(data),
    error: (error: CommonResponse<any>) => this.getIdentificationTypesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getIdentificationTypesShippingObserver = {
    next: (data: CommonResponse<any>) => this.getIdentificationTypesShippingNext(data),
    error: (error: CommonResponse<any>) => this.getIdentificationTypesShippingError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  // readonly getCountriesGlobalObserver = {
  //   next: (data: CommonResponse<any>) => this.getCountriesGlobalNext(data),
  //   error: (error: CommonResponse<any>) => this.getCountriesGlobalError(error),
  //   complete: () => this._pageLoadingService.hideLoadingGif()
  // };

  readonly getCountriesGlobalByLanguageCodeObserver = {
    next: (data: CommonResponse<any>) => this.getCountriesGlobalByLanguageCodeNext(data),
    error: (error: CommonResponse<any>) => this.getCountriesGlobalByLanguageCodeError(error),
    complete: () => (this._pageLoadingService.hideLoadingGif())
  };

  readonly getStatesGlobalObserver = {
    next: (data: CommonResponse<any>) => this.getStatesGlobalNext(data),
    error: (error: CommonResponse<any>) => this.getStatesGlobalError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getStatesGlobalShippingObserver = {
    next: (data: CommonResponse<any>) => this.getStatesGlobalShippingNext(data),
    error: (error: CommonResponse<any>) => this.getStatesGlobalShippingError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getCitiesGlobalObserver = {
    next: (data: CommonResponse<any>) => this.getCitiesGlobalNext(data),
    error: (error: CommonResponse<any>) => this.getCitiesGlobalError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getCitiesGlobalShippingObserver = {
    next: (data: CommonResponse<any>) => this.getCitiesGlobalShippingNext(data),
    error: (error: CommonResponse<any>) => this.getCitiesGlobalShippingError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  @ViewChild(PaymentMethodsComponent) paymentMethodsComponent!: PaymentMethodsComponent;

  constructor(
    private fb: FormBuilder,
    private paymentService: GeneralPaymentService,
    private _countryService: CountryService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
   }

  async ngOnInit(): Promise<void> {
    this._pageLoadingService.showLoadingGif();
    this.billingForm = this.fb.group({
      personType: ['', Validators.required],
      documentType: ['', Validators.required],
      idNumber: ['', Validators.required],
      fullName: ['', Validators.required],
      country: ['', Validators.required],
      dpto: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required]
    });

    // this.billingForm = this.fb.group({
    //   billingPersonType: [{ value: '', disabled: true }],
    //   billingFullName: [{ value: '', disabled: true }],
    //   billingIdNumber: [{ value: '', disabled: true }],
    //   billingCountry: [{ value: '', disabled: true }],
    //   billingAddress: [{ value: '', disabled: true }],
    //   billingEmail: [{ value: '', disabled: true }]
    // });

    this.shippingForm = this.fb.group({
      shippingPersonType: ['', Validators.required],
      shippingDocumentType: ['', Validators.required],
      shippingIdNumber: ['', Validators.required],
      shippingFullName: ['', Validators.required],
      shippingCountry: ['', Validators.required],
      shippingDpto: ['', Validators.required],
      shippingCity: ['', Validators.required],
      shippingPostalCode: ['', Validators.required],
      shippingAddress: ['', Validators.required],
      shippingEmail: ['', Validators.required],
      shippingPhoneNumber: ['', Validators.required]
    });

    this.country = localStorage.getItem('countryName') || '';
    // console.log(" country: ", this.country);

    const loggedUser = sessionStorage.getItem('logged_user');
    let userId: number = 0;

    if (loggedUser) {
      try {
        const parsedData = JSON.parse(loggedUser);
        userId = parseInt(parsedData.userId, 10);
      } catch (error) {
        console.error('Error parsing userId from sessionStorage', error);
      }
    }

    this.userId = userId;
    // console.log(" userId: ", this.userId);
    this.updateForm(); // Llamar a la función para actualizar los datos al inicia el componente
    
    // Suscribirse al cambio de idioma
    this.langChangeSubscription = this._translateService.onLangChange.subscribe(() => {
    this.updateForm(); // Llamar a la función para actualizar los datos cuando hay un cambio en el selector de idiomas
    });
    
    this.paymentService.paymentProcessing$.subscribe(isProcessing => {
      this.isProcessingPayment = isProcessing;
    });

  }

  /*
   * Copiar todos los datos seleccionados e ingresados del formulario de Facturación en el formulario de Despacho
   */
  copyBillingData(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.checkCopyData = checkbox.checked;
    
    if (checkbox.checked) {
      this.personTypesShipping = JSON.parse(JSON.stringify(this.personTypes));
      this.citiesGlobalShipping = JSON.parse(JSON.stringify(this.citiesGlobal));
      // this.identificationTypesShipping = JSON.parse(JSON.stringify(this.identificationTypes));
      // this.statesGlobalShipping = JSON.parse(JSON.stringify(this.statesGlobal));

      this.statesGlobalShipping = this.statesGlobal.map(type => ({ ...type }));
      this.identificationTypesShipping = this.identificationTypes.map(type => ({ ...type }));

      this.shippingForm.patchValue({
        shippingPersonType: this.billingForm.get('personType')?.value || '',
        shippingDocumentType: this.billingForm.get('documentType')?.value || '',
        shippingIdNumber: this.billingForm.get('idNumber')?.value || '',
        shippingFullName: this.billingForm.get('fullName')?.value || '',
        shippingCountry: this.billingForm.get('country')?.value || '',
        shippingDpto: this.billingForm.get('dpto')?.value || '',
        shippingCity: this.billingForm.get('city')?.value || '',
        shippingPostalCode: this.billingForm.get('postalCode')?.value || '',
        shippingAddress: this.billingForm.get('address')?.value || '',
        shippingEmail: this.billingForm.get('email')?.value || '',
        shippingPhoneNumber: this.billingForm.get('phoneNumber')?.value || ''
      });

      // this.shippingForm.disable();
      this.formSubscription = this.billingForm.valueChanges.subscribe(values => {
        this.shippingForm.patchValue({
          shippingPersonType: values.personType,
          shippingDocumentType: values.documentType,
          shippingIdNumber: values.idNumber,
          shippingFullName: values.fullName,
          shippingCountry: values.country,
          shippingDpto: values.dpto,
          shippingCity: values.city,
          shippingPostalCode: values.postalCode,
          shippingAddress: values.address,
          shippingEmail: values.email,
          shippingPhoneNumber: values.phoneNumber,
        });
      });
      // console.log('DocumentType  (Billing):', this.billingForm.get('documentType')?.value);
      // console.log('DocumentType (Shipping):', this.shippingForm.get('shippingDocumentType')?.value);
      this.cdr.detectChanges();
    } else {
    
      // this.shippingForm.enable();
      this.shippingForm.reset();

      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
        this.formSubscription = null;
      }
    }
  }

  /**
   * Funciones para comparar selectores de billingForm y shippingForm
   * @param o1 
   * @param o2 
   * @returns 
   */
  comparePersonType(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  compareDocumentType(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  compareCountry(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  compareDpto(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  compareCity(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
     // Desuscribirse cuando el componente se destruya para evitar fugas de memoria
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Actualizar formulacios cuando hay un cambio de idioma en el sistema
   */
  async updateForm(): Promise<void> { 
    this._pageLoadingService.showLoadingGif();
    
    await this.getLanguageCode();

    this.getPersonTypesByCountryCodeLanguageCode();
    
    this.getAllCountriesGlobalByLanguageCode();
  }

  getLanguageCode(): void {
    this.currentLang = this._translateService.currentLang;
    const lang = this.languageList.find(
      (item) => item.currentLang === this.currentLang
    );
    this.languageCode = lang ? lang.languageCode : '';
    // console.log(" languageCode: ", this.languageCode);    
    
    this.cleanFormLanguage();
  }

  /**
   * Limpiar los selectores de los formularios cuando se cambia el idioma del sistema
   */
  cleanFormLanguage(){
    this.billingForm.patchValue({
      personType: '',
      documentType: '',
      country: '',
      dpto: '',
      city: '',
    });

    this.shippingForm.patchValue({
      shippingPersonType: '',
      shippingDocumentType: '',
      shippingCountry: '',
      shippingDpto: '',
      shippingCity: '',
    });

    this.personTypes = [];
    this.personTypesShipping = [];
    this.identificationTypes = [];
    this.identificationTypesShipping = [];
    this.countriesGlobal = [];
    this.countriesGlobalShipping = [];
    this.statesGlobal = [];
    this.statesGlobalShipping = [];
    this.citiesGlobal = [];
    this.citiesGlobalShipping = [];
  }


  /**
   * Funciones llamadas por los Observer
   */
  getPersonTypesNext(data: CommonResponse<any>) {
    this.personTypes = data.data;
    this.personTypesShipping = JSON.parse(JSON.stringify(this.personTypes));
  }

  getPersonTypesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getPersonTypesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getPersonTypesError');
    }
  }

  getIdentificationTypesNext(data: CommonResponse<any>) {
    this.identificationTypes = data.data;
    if (this.checkCopyData) {
      this.identificationTypesShipping = this.identificationTypes.map(type => ({ ...type }));
    }
  }

  getIdentificationTypesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getIdentificationTypesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getIdentificationTypesError');
    }
  }

  getIdentificationTypesShippingNext(data: CommonResponse<any>) {
    this.identificationTypesShipping = data.data;
  }

  getIdentificationTypesShippingError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getIdentificationTypesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getIdentificationTypesError');
    }
  }

  // getCountriesGlobalNext(data: CommonResponse<any>) {
  //   console.log(" -- getCountriesGlobalNext -- ");
  //   console.log(" -- getCountriesGlobalNext - data: ", data);
  //   this.countriesGlobal = data.data;
  //   console.log("getCountriesGlobalNext - countriesGlobal: ", this.countriesGlobal);
  // }

  // getCountriesGlobalError(error: CommonResponse<any>) {
  //   console.log(" -- getCountriesGlobalError -- ");
  //   this._pageLoadingService.hideLoadingGif();

  //   if(error.statusCode == 404) {
  //     this._snackbarService.openStandardSnackBar('getCountriesError404');
  //   }
  //   else {
  //     this._snackbarService.openStandardSnackBar('getCountriesError');
  //   }
  // }

  getCountriesGlobalByLanguageCodeNext(data: CommonResponse<any>) {
    this.countriesGlobal = data.data;
    this.countriesGlobalShipping = JSON.parse(JSON.stringify(this.countriesGlobal));
  }

  getCountriesGlobalByLanguageCodeError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getCountriesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getCountriesError');
    }
  }

  getStatesGlobalNext(data: CommonResponse<any>) {
    this.statesGlobal = data.data;
    if (this.checkCopyData) {
      this.statesGlobalShipping = this.statesGlobal.map(type => ({ ...type }));
    }
  }

  getStatesGlobalError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getStatesGlobalError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getStatesGlobalError');
    }
  }

  getStatesGlobalShippingNext(data: CommonResponse<any>) {
    this.statesGlobalShipping = data.data;
  }

  getStatesGlobalShippingError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getStatesGlobalError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getStatesGlobalError');
    }
  }

  getCitiesGlobalNext(data: CommonResponse<any>) {
    this.citiesGlobal = data.data;
    if (this.checkCopyData) { 
      this.citiesGlobalShipping = this.citiesGlobal.map(type => ({ ...type }));
    }
  }

  getCitiesGlobalError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getCitiesGlobalError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getCitiesGlobalError');
    }
  }

  getCitiesGlobalShippingNext(data: CommonResponse<any>) {
    this.citiesGlobalShipping = data.data;
  }

  getCitiesGlobalShippingError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getCitiesGlobalError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getCitiesGlobalError');
    }
  }

  // async getAllCountriesGlobal() {
  //   console.log("  ---- getAllCountriesGlobal ----");
    
  //   const countries = await this._countryService.getAllCountriesGlobal().toPromise();
  
  //   // Verificar si `countries` no es undefined antes de llamar a next
  //   if (countries) {
  //     this.getCountriesGlobalObserver.next(countries);
  //   } else {
  //     console.error('No se pudo obtener los países');
  //   }
  // }


  /**
   * Funciones de peticiones al Service
   */
  getPersonTypesByCountryCodeLanguageCode() {
    this._pageLoadingService.showLoadingGif();
    this.countryCode = localStorage.getItem('countryCode') || this.countryCode;
    
    this._countryService.getPersonTypesByCountryCodeLanguageCode(this.countryCode, this.languageCode).subscribe(this.getPersonTypesObserver);
  }

  getIdentificationTypeByPersonTypeId(personTypeId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getIdentificationTypeByPersonTypeId(personTypeId).subscribe(this.getIdentificationTypesObserver);
  }

  getIdentificationTypeByPersonTypeIdShipping(personTypeId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getIdentificationTypeByPersonTypeId(personTypeId).subscribe(this.getIdentificationTypesShippingObserver);
  }

  getAllCountriesGlobalByLanguageCode() {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getAllCountriesGlobalByLanguageCode(this.languageCode).subscribe(this.getCountriesGlobalByLanguageCodeObserver);
  }

  getStatesGlobalByCountryIdLanguageCode(countryId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getStatesGlobalByCountryIdLanguageCode(countryId, this.languageCode).subscribe(this.getStatesGlobalObserver);
  }

  getStatesGlobalByCountryIdLanguageCodeShipping(countryId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getStatesGlobalByCountryIdLanguageCode(countryId, this.languageCode).subscribe(this.getStatesGlobalShippingObserver);
  }

  
  getCitiesGlobalByStateIdLanguageCode(stateId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getCitiesGlobalByStateIdLanguageCode(stateId, this.languageCode).subscribe(this.getCitiesGlobalObserver);
  }
  
  getCitiesGlobalByStateIdLanguageCodeShipping(stateId: number) {
    this._pageLoadingService.showLoadingGif();
    this._countryService.getCitiesGlobalByStateIdLanguageCode(stateId, this.languageCode).subscribe(this.getCitiesGlobalShippingObserver);
  }
 

  /**
   * Funciones de cambiar información en los selectores 
   */
  onPersonTypeChange(event: any, section: string = "billing"): void {
    let personTypeName = event.value;
    if (section == "billing") {
      let personTypeid = this.personTypes.find(
        (item) => item.name === personTypeName
      )?.personTypeId;
      this.getIdentificationTypeByPersonTypeId(Number(personTypeid));
    } else {
      let personTypeid = this.personTypesShipping.find(
        (item) => item.name === personTypeName
      )?.personTypeId;
      this.getIdentificationTypeByPersonTypeIdShipping(Number(personTypeid));
    }
  }

  onCountryChange(event: any, section: string = "billing"): void {
    let countryName = event.value;
    if (section == "billing") {
      let countryId = this.countriesGlobal.find(
        (item) => item.countryName === countryName
      )?.countryId;
      this.getStatesGlobalByCountryIdLanguageCode(Number(countryId))
    } else {
      let countryId = this.countriesGlobalShipping.find(
        (item) => item.countryName === countryName
      )?.countryId;
      this.getStatesGlobalByCountryIdLanguageCodeShipping(Number(countryId));
    }
  }

  onStateChange(event: any, section: string = "billing"): void {
    let stateName = event.value;
    if (section == "billing") {
      let stateId = this.statesGlobal.find(
        (item) => item.stateName === stateName
      )?.stateId;
      this.getCitiesGlobalByStateIdLanguageCode(Number(stateId))
    } else {
      let stateId = this.statesGlobalShipping.find(
        (item) => item.stateName === stateName
      )?.stateId;
      this.getCitiesGlobalByStateIdLanguageCodeShipping(Number(stateId));
    }
  }

  // Método para combinar datos de ambos formularios
  getCombinedFormData() {
    return {
      billingData: this.billingForm.value,
      shippingData: this.shippingForm.value
    };
  }

  
}
