import { Component } from '@angular/core'
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { Category } from '../../models/category-product.models';
import { CategoryProductService } from '../../services/category-product.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateCategoriesComponent } from 'src/app/users/components/create-categories/create-categories.component';
import { EditCategoriesComponent } from 'src/app/users/components/edit-categories/edit-categories.component';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent {

  private readonly getAllCategoriesObserver = {
    next: (data: CommonResponse<any>) => this.getAllCategoriesNext(data),
    error: (error: CommonResponse<any>) => this.getAllCategoriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  private readonly deleteCategoryObserver = {
    next: (data: CommonResponse<any>) => this.deleteCategoryNext(data),
    error: (error: CommonResponse<any>) => this.deleteCategoryError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  deleteTitleKey: string = 'category.delete_title';
  deleteMessageKey: string = 'category.delete_message';
  currentLang: string = "";
  CategoriesData: any[] = [];
  newCategory: Category;
  
  constructor(private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _categoryService: CategoryProductService,
    private _translate: TranslateService,
    private _dialog: MatDialog) {
    this._pageLoadingService.showLoadingGif();

    this.currentLang =this._translate.currentLang;

    this._translate.onLangChange.subscribe(() => {
      this.currentLang =this._translate.currentLang;
    });

    this._categoryService.getCategory()
      .subscribe(this.getAllCategoriesObserver);

  }

  getAllCategoriesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteUserError`);
  }

  getAllCategoriesNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this.CategoriesData = data.data;

    //console.log('categories', this.CategoriesData);
    

    // Convertir la cadena JSON en un objeto JavaScript
    this.CategoriesData.forEach(element => {
      const additionalDataObj = JSON.parse(element.additionalData);
      element.additionalData = additionalDataObj;
    });

    // Acceder a los datos del idioma actual del sistema
    // const currentLanguageData = this.CategoriesData.additionalData['en'];

    // // Acceder a los campos específicos del idioma actual
    // const currentLanguage = currentLanguageData.language;
    // const currentCategoryName = currentLanguageData.categoryName;

    // // Log de los datos del idioma actual del sistema
    // console.log("Idioma actual:", currentLanguage);
    // console.log("Nombre de la categoría actual:", currentCategoryName);
    
  }

  addCategory() {
    this.CategoriesData.push({ ...this.newCategory });
    this.newCategory = { name: '', isDelete: false, additionalData: '' };
  }

  addNewCategory() {
    const confirmPurchaseDialogRef = this._dialog.open(CreateCategoriesComponent, {
      height: '90%',
      width: '90%',
    });

    confirmPurchaseDialogRef.beforeClosed().subscribe(result => {
      this._categoryService.getCategory()
      .subscribe(this.getAllCategoriesObserver);
    })
    /**
     *     this._categoryService.getCategory()
    .subscribe(this.getAllCategoriesObserver);
     */

  }

  editCategory(id: number, name: string, status: number) {
    const confirmDeleteDialogRef = this._dialog.open(EditCategoriesComponent, {
      width: '450px',
      data: { userId: id, name: name, status: status },
    });
  }

  // showDeleteUserConfirmation(titleKey: string, messageKey: string, categoryName: string, categoryId: string) {
  //   const title = this.translateService.instant(titleKey);
  //   const message = this.translateService.instant(messageKey);

  showDeleteUserConfirmation(categoryName: string, categoryId: number) {
    const title = this._translate.instant(this.deleteTitleKey);
    const message = this._translate.instant(this.deleteMessageKey);
    
    const data: DialogData = {
      title: title,
      message: `${message} "${categoryName}"?`,
      userInteractResult: false
    };

    const confirmDeleteDialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: '350px',
      data: data
    });

    confirmDeleteDialogRef.afterClosed().subscribe(result => {
      this._pageLoadingService.showLoadingGif();

      if (result) {
        this._categoryService.deleteCategory(categoryId)
          .subscribe(this.deleteCategoryObserver);
      }

      this._pageLoadingService.hideLoadingGif();
    });
  }

  onCheckboxChange(category: any): void {
    // Cambia el estado de isDelete (0 para habilitado, 1 para deshabilitado)
    category.isDelete = category.isDelete === 0 ? 1 : 0;
  
    // Llama al backend para persistir el cambio, si es necesario
    this._categoryService.updateCategory(category.id, { isDelete: category.isDelete })
      .subscribe(data => {
        //console.log(data);
      });
  }

  deleteCategoryError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteCategoryError`);
  }

  deleteCategoryNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar(`deleteCategorySuccess`,'ok');
    //this.CategoriesData = data.data;
    this._categoryService.getCategory()
    .subscribe(this.getAllCategoriesObserver);
    //window.location.reload()
  }

}