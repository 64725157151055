import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../services/report.service';
import { PaymentDetails } from '../../models/payment.model';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ListOrderBooksComponent } from 'src/app/payment/components/list-order-books/list-order-books.component';
import { environment as env } from 'src/environments/environment';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-main-view-report',
  templateUrl: './main-view-report.component.html',
  styleUrls: ['./main-view-report.component.scss']
})
export class MainViewReportComponent implements OnInit {
  displayedColumns: string[] = [
    'paymentDetailsId', 'amount', 'provider', 'status', 'orderDetailsId', 'userEmail', 'created_at', 'userCountries','paymentProducts'
  ];

  // New date properties
  dateInit: string = ''; // To capture the start date
  dateEnd: string = '';  // To capture the end date
  filterData:any;
  dataSource: MatTableDataSource<PaymentDetails> = new MatTableDataSource<PaymentDetails>();
  userData:any;
  userCountries:any;
  userId: number; 

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _reportService: ReportService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _userService:UserService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._pageLoadingService.showLoadingGif();
    // Recuperar el dato del sessionStorage
  const dataSessionstorage = sessionStorage.getItem('logged_user');

  // Verificar si el dato no es null antes de parsear
  if (dataSessionstorage) {
    const parsedData = JSON.parse(dataSessionstorage);
    this.getUserinfo(parsedData?.userId);
    this.userId = parsedData?.userId; 

    //console.log('ID del usuario logueado:', parsedData?.userId);
  } else {
    console.error('No se encontró la clave "logged_user" en el sessionStorage.');
  }

  }
  
  getUserinfo(userId:number){
    //console.log('entro a get userinfo:',userId);

    this._userService.getUser(userId)
    .subscribe({
      next: (data) => {
        this.userData=data.data;
        this.userCountries = this.userData.countries;
        //console.log('Datos recibidos:', this.userCountries);
        this.getInfoCountriesByUserId(this.userCountries);
      },
      error: (err) => {
        console.error('Error al obtener los datos del usuario:', err);
      },
      // complete: () => {
      //   //console.log('Petición completada.');
      //   this._pageLoadingService.hideLoadingGif();// esconde la animacion de cargando cuando se completa la peticion
      // }
    });
  }


  getInfoCountriesByUserId(countriesUser:any){
    this._pageLoadingService.showLoadingGif();

    //console.log('entro a getInfoCountriesByUserId');

    this._reportService.getPaymentHistory(countriesUser).subscribe({
      next: (data: CommonResponse<PaymentDetails[]>) => this.getReportPaymentNext(data),
      error: (error: CommonResponse<any>) => this.getReportPaymentError(error),
      complete: () => this._pageLoadingService.hideLoadingGif()
    });
  }

  getReportPaymentNext(data: CommonResponse<PaymentDetails[]>) {
    //let countryCode = localStorage.getItem('countryCode');
    //console.log('countryCodes:',this.userCountries);
    // this.filterData = data.data?.filter(item => item.order_details.country_general_info?.countryCode === 'esp');
    // console.log('data filtrada:',this.filterData);
    // const testCountries = ['col'];
    this.dataSource.data = data.data?.filter(item => this.userCountries.includes(item.order_details.country_general_info?.countryCode)) || [];

    //this.dataSource.data = data.data?.filter(item => this.userCountries.includes(item.order_details.country_general_info?.countryCode)) || [];
    //console.log('data filtrada:',this.dataSource.data);

    this.dataSource.data.forEach(payment => {
      payment.created_at = this.formatDate(payment.created_at);
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getReportPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if (error.statusCode === 404) {
      this._snackbarService.openStandardSnackBar('getReportPaymentError404');
    } else {
      this._snackbarService.openStandardSnackBar('getReportPaymentError');
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: true 
    };
    return date.toLocaleDateString('es-ES', options);
  }

  // Show more details modal
  showMoreDetails(paymentId: number) {
    const confirmPurchaseDialogRef = this._dialog.open(ListOrderBooksComponent, {
      height: '90%',
      width: '90%',
      data: { paymentId } // Pass the payment ID to the dialog component
    });
  }

  // Generate the report download link dynamically
  generateReportLink(): string {
    if (!this.dateInit || !this.dateEnd) {
      return '#'; // Disable the link if dates are not set
    }

    const report = '1'; // Identifier for the report
    const reportName = 'Reporte_de_compras'; // Name of the report
    return `${env.url_api}/v1/payment/downloadReport/${report}/${reportName}/${this.dateInit}/${this.dateEnd}/${this.userId}`;
  }


  formatAmount(amount: string, currency: { currencyId: number; symbol: string }): string {
    if (!currency) {
      return amount; // En caso de que no haya moneda, regresa el monto sin formato
    }

    const { currencyId, symbol } = currency;

    if (currencyId === 4) {
      // Agrega el símbolo al final
      return `${amount} ${symbol}`;
    } else {
      // Agrega el símbolo al inicio
      return `${symbol} ${amount}`;
    }
  }
}
