import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/books/models/product.model';
import { Country } from 'src/app/countries/models/country.model';
import { CountryService } from 'src/app/countries/services/country.service';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { Language } from 'src/app/languages/models/language.model';
import { LanguageService } from 'src/app/languages/services/language.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, map, startWith, switchMap, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserRegister } from '../../models/user-register.model';
import { UserService } from '../../services/user.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

type UserUpdate = {
  userId: number;
  emailData: {
    email: string;
    update: boolean;
  };
  nameData: {
    name: string;
    update: boolean;
  };
  countryCodeData: {
    countryCode: string;
    update: boolean;
  };
  defaultLanguageCodeData: {
    defaultLanguageCode: string;
    update: boolean;
  };
  roleData: {
    roles: number[];
    update: boolean;
  };
  passwordData:
  {
    password?: string,
    update: boolean
  };
  productsSelected: Product[];
};

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent {

  editUserForm: FormGroup;
  countries: Country[] = [];
  languages: Language[] = [];
  products: Product[] = [];
  productsSelected: Product[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  filteredProducts: Observable<Product[]>;
  productCtrl = new FormControl('');
  user: UserRegister;
  roles: any[] = [];

  userUpdate: UserUpdate = {
    userId: 0,
    emailData: { email: "", update: false },
    nameData: { name: "", update: false },
    countryCodeData: { countryCode: "", update: false },
    defaultLanguageCodeData: { defaultLanguageCode: "", update: false },
    roleData: { roles: [], update: false },
    passwordData: { password: "", update: false },
    productsSelected: [],
  };

  isAdmin: boolean = false;
  isCountryAdmin: boolean = false;
  selectedCountries: Country[] = [];
  countriesUserUpdate: any = {};
  arrayCountriesToSend: any[] = [];

  isAdminCountry: boolean = false;
  availableRoles: { value: number; label: string }[] = [];
  rolesArray = [
    { value: 2, label: 'users.administrator' },
    { value: 1, label: 'users.user' },
    { value: 4, label: 'users.admin_country' }
  ];

  countryCodeLoggedUser: string = '';
  countriesFiltered: Country[] = [];
  countriesOfUser!: any;
  userIdLogged!: any;

  @ViewChild('productInput') productInput: ElementRef<HTMLInputElement>;

  readonly getCountriesObserver = {
    next: (data: CommonResponse<any>) => this.getCountriesNext(data),
    error: (error: CommonResponse<any>) => this.getCountriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getAllProductsObserver = {
    next: (data: CommonResponse<any>) => this.getAllProductsNext(data),
    error: (error: CommonResponse<any>) => this.getAllProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly updateNewUserObserver = {
    next: (data: CommonResponse<any>) => this.updateNewUserNext(data),
    error: (error: CommonResponse<any>) => this.updateNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getUserObserver = {
    next: (data: CommonResponse<any>) => this.getUserNext(data),
    error: (error: CommonResponse<any>) => this.getUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
    public dialogRef: MatDialogRef<EditUsersComponent>,
    private fb: FormBuilder,
    private _translateService: TranslateService,
    private _countryService: CountryService,
    private _languageService: LanguageService,
    private _editorialService: EditorialService,
    private _userService: UserService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
  ) {
    this._pageLoadingService.showLoadingGif();
    this.userUpdate.userId = data.userId;

    this._countryService.getAllCountries().subscribe(this.getCountriesObserver);
    this._languageService.getAllLanguages().subscribe(this.getLanguagesObserver);
    this._editorialService.getAllproducts(2).subscribe(this.getAllProductsObserver);
    this._editorialService.getAllproducts(2).pipe(
      tap(() => {
        // Aquí puedes ejecutar código adicional si es necesario antes de la suscripción
      }),
      switchMap(() => {
        return this._userService.getUser(data.userId);
      })
    ).subscribe(this.getUserObserver);

    this.editUserForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      password: ['', Validators.required],
      countryCode: ['default', Validators.required],
      defaultLanguageCode: ['default', Validators.required],
      roles: ['', Validators.required],
      defaultProducts: ['default'],
      adminEditableCountries: []
    });
  }

  ngOnInit() {
    const logedUser = sessionStorage.getItem('logged_user');
    if (logedUser) {
      const userData = JSON.parse(logedUser);
      const rolesData = userData.roles;
  
      this.userIdLogged = userData.userId;
      this.isAdminCountry = ['country_admin', 'editorial_admin'].some(role => rolesData.includes(role));
  
      this.availableRoles = this.isAdminCountry
        ? this.rolesArray.filter(role => role.value !== 2)
        : this.rolesArray;
  
      if (this.isAdminCountry) {
        this.getCountriesByUser();
      }
    } else {
      console.error('No hay datos de usuario en la sesión');
    }
  }

  getCountriesByUser() {
    this._countryService.getCountriesByUser(this.userIdLogged)
      .subscribe({
        next: (data) => {
          this.countriesOfUser = data.data;
  
          // 🔹 Filtrar países inmediatamente después de cargarlos
          this.countriesFiltered = this.getFilteredCountries();
  
          // 🔹 Asignar países al formulario
          this.selectedCountries = this.getFilteredCountries();
          this.editUserForm.get('adminEditableCountries')?.setValue(this.selectedCountries);
        },
        error: (err) => {
          console.error('Error al obtener los países:', err);
        },
        complete: () => {
          this._pageLoadingService.hideLoadingGif();
        }
      });
  }
  

  modalInteractTrue() {
    this.dialogRef.close(true);
  }

  getCountriesNext(data: CommonResponse<any>) {
    this.countries = data.data;
    this.editUserForm.get('countryCode')?.setValue(this.countries[0].countryCode);
  }

  getCountriesError(error: CommonResponse<any>) {
  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;
    this.editUserForm.get('defaultLanguageCode')?.setValue(this.languages[0].languageCode);
  }

  getLanguagesError(error: CommonResponse<any>) {
  }

  getAllProductsNext(data: CommonResponse<any>) {
    this.products = Object.values(data.data);
    this.autoCompleteFilteredProducts();
    this.editUserForm.get('defaultProducts')?.setValue(this.products);
  }

  getAllProductsError(error: CommonResponse<any>) {
  }

  getUserNext(data: CommonResponse<any>) {
    this.user = data.data;
    this.editUserForm.get('name')?.setValue(this.user.name);

    this.selectedCountries = this.countries.filter((country: any) =>
      (this.user["countries"] || []).includes(country.countryCode)
    );

    this.editUserForm.get('defaultLanguageCode')?.setValue(this.user.defaultLanguageCode);
    this.editUserForm.get('countryCode')?.setValue(this.user.countryCode);
    const emailControl = this.editUserForm.get('email');

    const asyncValidators = emailControl?.asyncValidator;

    emailControl?.clearAsyncValidators();
    emailControl?.setValue(this.user.email);

    if (asyncValidators) {
      emailControl?.setAsyncValidators(asyncValidators);
    }

    const roleControl = this.editUserForm.get('roles');
    const roles = (this.user as any).roles;

    let selectedRole = '';

    if (roles && Array.isArray(roles)) {
      if (roles.length === 1 && roles[0].roleId === 1) {
        this.userUpdate.roleData = this.userUpdate.roleData || { roles: [], update: false };
        this.userUpdate.roleData.roles.push(1);
        selectedRole = '1'; // Usuario
      } else if (roles.length > 1 &&
        roles.some((role: any) => role.roleId === 4) &&
        roles.some((role: any) => role.roleId === 3)
      ) {
        selectedRole = '2'; // Administrador
        this.userUpdate.roleData = this.userUpdate.roleData || { roles: [], update: false };
        this.userUpdate.roleData.roles.push(2);
        this.userUpdate.roleData.roles.push(3);
        this.isAdmin = true;
      } else if (roles.some((role: any) => role.roleId === 4)
      ) {
        selectedRole = '4'; // Administrador de pais
        this.userUpdate.roleData = this.userUpdate.roleData || { roles: [], update: false };
        this.userUpdate.roleData.roles.push(4);
        this.isCountryAdmin = true;
      }
      roleControl?.setValue(selectedRole);
    }

    this.editUserForm.get('defaultProducts')?.setValue(this.products);

    var productsUser: Array<any> = this.user.shopCartProducts;

    for (const productUser of productsUser) {
      const productMatch = this.products.find(product => product.productId === productUser.productId);

      if (productMatch) {
        const selectedProductIndex = this.productsSelected.findIndex(selectedProduct => selectedProduct.productId === productUser.productId);

        if (selectedProductIndex === -1) {
          this.productsSelected.push({ ...productMatch, quantity: 1 });
        } else {
          this.productsSelected[selectedProductIndex].quantity += 1;
        }
      }
    }

    this.userUpdate.nameData = { name: this.user.name, update: false };
    this.userUpdate.passwordData = { password: this.user.password, update: false };
    this.userUpdate.roleData.update = false;
    this.userUpdate.emailData = { email: this.user.email, update: false },
      this.userUpdate.countryCodeData = { countryCode: this.user.countryCode, update: false },
      this.userUpdate.defaultLanguageCodeData = { defaultLanguageCode: this.user.defaultLanguageCode, update: false },
      this.userUpdate.productsSelected = this.productsSelected;

    this.autoCompleteFilteredProducts();
    this.productInput.nativeElement.value = '';
    this.productCtrl.setValue(null);

    return;
  }

  getUserError(error: CommonResponse<any>) { }

  getCountryNameByLanguage(country: Country) {
    return country.country_general_info.find(cgi => cgi.language.languageIsoCode == this._translateService.currentLang)?.name;
  }

  add(event: MatChipInputEvent): void {
    this.autoCompleteFilteredProducts();

    event.chipInput!.clear();
    this.productCtrl.setValue(null);
  }

  /** 
   * Eliminar un producto del selector de productos añadidos
   * @param product  elemento a eliminar del array
   */
  remove(product: any): void {
    const index = this.productsSelected.indexOf(product);

    if (index >= 0) {
      this.productsSelected.splice(index, 1);
    }
  }

  /** 
   * Seleccionar un producto del selector de productos a añadir
   * @param event  evento de tipo object con la información de la opción seleccionada
   */
  selected(event: MatAutocompleteSelectedEvent): void {

    if (!this.productsSelected.includes(event.option.value)) {
      this.productsSelected.push(event.option.value);
      this.productsSelected[this.productsSelected.length - 1].quantity = this.productsSelected[this.productsSelected.length - 1].availability > 0 ? 1 : 0;
    }

    this.autoCompleteFilteredProducts();
    this.productInput.nativeElement.value = '';
    this.productCtrl.setValue(null);
  }

  /** 
   * Cambiar la cantidad a asignar de un producto específico
   * @param event   evento de tipo object con la información de la opción seleccionada
   * @param change  opción de incrementar o disminuir la cantidad del producto
   */
  changeQuantity(event: Product, change: string) {
    change == 'lower' ? event.quantity-- : event.quantity++;
    this.productsSelected.map(product => {
      if (product.productId === event.productId) {
        product.quantity = event.quantity;
        return;
      }
    });
  }

  autoCompleteFilteredProducts() {
    this.filteredProducts = this.productCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter(product) : this.products.slice())
      ),
    );
  }

  private _filter(value: string): Product[] {
    const filterValue = value && typeof value === 'string' ? value.toLowerCase() : '';
    return this.products.filter(p => p.product_general_info[0].title.toLowerCase().includes(filterValue));
  }

  getProductNameByLanguage(product: Product) {
    const currentLang = this._translateService.currentLang;
    const productTitles = product.product_general_info
      .filter(pgi => pgi.language.languageIsoCode == currentLang);

    if (productTitles.length > 0) {
      return productTitles[0].title;
    }
    return "";
  }

  updateNewUserNext(data: CommonResponse<any>) {
    if (data.statusCode >= 200 && data.statusCode < 300) {
      this._snackbarService.openStandardSnackBar('successfullyEditUser', 'ok');
    }
  }

  updateNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('errorEditUser');
    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  editUserFromAdminOld() {
    const productsId = this.productsSelected.map(x => x.productId);

    var productsQuantity = this.productsSelected.map(product => ({
      productId: product.productId,
      quantity: product.quantity
    }));

    if (this.user.name !== this.editUserForm.get("name")?.value) {
      this.userUpdate.nameData.name = this.editUserForm.get("name")?.value;
      this.userUpdate.nameData.update = true;
    }

    if (this.editUserForm.get("password")?.value !== '') {
      this.userUpdate.passwordData = {
        password: this.editUserForm.get("password")?.value,
        update: true
      };
    } else {
      this.userUpdate.passwordData = {
        password: undefined,
        update: false
      };
    }

    if (this.user.email !== this.editUserForm.get("email")?.value) {
      this.userUpdate.emailData.email = this.editUserForm.get("email")?.value;
      this.userUpdate.emailData.update = true;
    }
    if (this.user.countryCode !== this.editUserForm.get("countryCode")?.value) {
      this.userUpdate.countryCodeData.countryCode = this.editUserForm.get("countryCode")?.value;
      this.userUpdate.countryCodeData.update = true;
    }
    if (this.user.defaultLanguageCode !== this.editUserForm.get("defaultLanguageCode")?.value) {
      this.userUpdate.defaultLanguageCodeData.defaultLanguageCode = this.editUserForm.get("defaultLanguageCode")?.value;
      this.userUpdate.defaultLanguageCodeData.update = true;
    }

    this._userService.updateUserFromAdmin(this.userUpdate)
      .subscribe(this.updateNewUserObserver);

    this.dialogRef.close(true);
  }

  async editUserFromAdmin() {
    const productsId = this.productsSelected.map(x => x.productId);

    var productsQuantity = this.productsSelected.map(product => ({
      productId: product.productId,
      quantity: product.quantity
    }));

    if (this.user.name !== this.editUserForm.get("name")?.value) {
      this.userUpdate.nameData.name = this.editUserForm.get("name")?.value;
      this.userUpdate.nameData.update = true;
    }

    if (this.editUserForm.get("password")?.value !== '') {
      this.userUpdate.passwordData = {
        password: this.editUserForm.get("password")?.value,
        update: true
      };
    } else {
      this.userUpdate.passwordData = {
        password: undefined,
        update: false
      };
    }

    if (this.user.email !== this.editUserForm.get("email")?.value) {
      this.userUpdate.emailData.email = this.editUserForm.get("email")?.value;
      this.userUpdate.emailData.update = true;
    }
    if (this.user.countryCode !== this.editUserForm.get("countryCode")?.value) {
      this.userUpdate.countryCodeData.countryCode = this.editUserForm.get("countryCode")?.value;
      this.userUpdate.countryCodeData.update = true;
    }
    if (this.user.defaultLanguageCode !== this.editUserForm.get("defaultLanguageCode")?.value) {
      this.userUpdate.defaultLanguageCodeData.defaultLanguageCode = this.editUserForm.get("defaultLanguageCode")?.value;
      this.userUpdate.defaultLanguageCodeData.update = true;
    }

    const selectedRole = Number(this.editUserForm.get("roles")?.value);
    const isRoleMatched = this.userUpdate.roleData.roles.some(role => role === selectedRole);
    if (!isRoleMatched) {
      this.userUpdate.roleData.roles = [];
      if (selectedRole === 1) {
        this.userUpdate.roleData.roles.push(1);
      } else if (selectedRole === 2) {
        this.userUpdate.roleData.roles.push(2);
        this.userUpdate.roleData.roles.push(3);
      } else if (selectedRole === 4) {
        this.userUpdate.roleData.roles.push(4);
      }
      this.userUpdate.roleData.update = true;
    }
    
    const countriesToAdd = this.editUserForm.get('adminEditableCountries')?.value;

    if (countriesToAdd && countriesToAdd.length > 0) {
      await this.addCountriesToUser(this.userUpdate.userId, this.userUpdate.roleData.roles[0]);
      this._snackbarService.openStandardSnackBar('successfullyAddUserAndCountry', 'ok');
    } else {
      this._snackbarService.openStandardSnackBar('successfullyAddUser', 'ok');
    }

    this.userUpdate.productsSelected = [];

    this._userService.updateUserFromAdmin(this.userUpdate).subscribe(this.updateNewUserObserver);
    this.dialogRef.close(true);
  }

  onRoleChange(event: Event) {
    const selectedRole = (event.target as HTMLSelectElement).value;
    this.isAdmin = selectedRole === '2';
    this.isCountryAdmin = selectedRole === '4';

    if (this.isAdminCountry === true) {
      this.countriesFiltered = this.getFilteredCountries();
    } else {
      this.countriesFiltered = this.countries;
    }

    if (!this.isCountryAdmin) {
      this.editUserForm.get('adminEditableCountries')?.setValue('');
    }
  }

  getFilteredCountries(): any {
    const allowedCountryCodes = this.countriesOfUser.map((country: any) => country.countryCode);
    return this.countries.filter(data => allowedCountryCodes.includes(data.countryCode));
  }

  addCountriesToUser(userId: number, userRol: number) {
    const countriesToAdd = this.editUserForm.get('adminEditableCountries')?.value;

    if (countriesToAdd && countriesToAdd.length > 0) {
      this.arrayCountriesToSend = countriesToAdd.map((country: { countryCode: any }) => ({
        userId: userId,
        userRol: userRol,
        countryCode: country.countryCode,
      }));
      this._countryService.updateCountryUser(this.arrayCountriesToSend).subscribe({
        next: (response) => {
        },
        error: (error) => {
          console.error("Error al actualizar los países:", error);
        },
      });
    } else {
      console.warn("No se seleccionaron países");
    }
  }
}
