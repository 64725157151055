import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { UserRegister } from '../../models/user-register.model';
import { UserService } from '../../services/user.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { LanguageService } from 'src/app/languages/services/language.service';
import { CountryService } from 'src/app/countries/services/country.service';
import { Country } from 'src/app/countries/models/country.model';
import { Language } from 'src/app/languages/models/language.model';
import { TranslateService } from '@ngx-translate/core';
import { ShopCartService } from 'src/app/books/services/shop-cart.service';
import { RegisterUsers } from '../../models/register-users.model';

@Component({
  selector: 'app-register-users',
  templateUrl: './register-users.component.html',
  styleUrls: ['./register-users.component.scss']
})
export class RegisterUsersComponent implements AfterViewInit {

  countries: Country[] = [];
  languages: Language[] = [];
  registerForm: FormGroup;
  countryName: string = '';

  readonly getLanguagesObserver = {
    next: (data: CommonResponse<any>) => this.getLanguagesNext(data),
    error: (error: CommonResponse<any>) => this.getLanguagesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly getCountriesObserver = {
    next: (data: CommonResponse<any>) => this.getCountriesNext(data),
    error: (error: CommonResponse<any>) => this.getCountriesError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  readonly registerNewUserObserver = {
    next: (data: CommonResponse<any>) => this.registerNewUserNext(data),
    error: (error: CommonResponse<any>) => this.registerNewUserError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(
    private _userService: UserService,
    private fb: FormBuilder,
    private _pageLoadingService: PageLoadingService,
    private _router: Router,
    private _snackbarService: SnackbarService,
    private _languageService: LanguageService,
    private _countryService: CountryService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private _shopCartService: ShopCartService) {
    this._pageLoadingService.showLoadingGif();

    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required, Validators.email],
      password: ['', Validators.required],
      roles: [[1], Validators.required],
      countryCode: ['default', Validators.required],
      defaultLanguageCode: ['default', Validators.required],
    });

    this._languageService.getAllLanguages().subscribe(this.getLanguagesObserver);
    this._countryService.getAllCountries().subscribe(this.getCountriesObserver);

    const storedCountryName = localStorage.getItem('countryName');
    
    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
    }
  }

  ngOnInit() {
    const storedCountryName = localStorage.getItem('countryName');

    if (storedCountryName !== null) {
      this.countryName = storedCountryName;
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    } else {
      this._countryService.getCountryName$().subscribe(countryName => {
        this.countryName = countryName;
        this.cdr.detectChanges(); // Marcar la vista como actualizada
      });
    }
  }

  ngAfterViewInit(){
    
  }

  getLanguagesNext(data: CommonResponse<any>) {
    this.languages = data.data;

    this.registerForm.get('defaultLanguageCode')?.setValue(this.languages[0].languageCode);
  }

  getLanguagesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getLanguagesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getLanguagesError');
    }
  }

  getCountriesNext(data: CommonResponse<any>) {
    this.countries = data.data;

    this.registerForm.get('countryCode')?.setValue(this.countries[0].countryCode);
  }

  getCountriesError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if(error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('getCountriesError404');
    }
    else {
      this._snackbarService.openStandardSnackBar('getCountriesError');
    }
  }

  registerNewUserNext(data: CommonResponse<any>) {
    if(data.statusCode >= 200 && data.statusCode < 300) {
      this._router.navigate(['/login']);
      this._snackbarService.openStandardSnackBar('registerNewUserNext','ok');
    }
  }

  registerNewUserError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    // console.log(error);

    this._snackbarService.openStandardSnackBar(error.errors[0]);
  }

  create() {
    this._pageLoadingService.showLoadingGif();

    const newUserRequest: RegisterUsers = {
      name: this.registerForm.get("name")?.value,
      email: this.registerForm.get("email")?.value,
      password: this.registerForm.get("password")?.value,
      roles: this.registerForm.get("roles")?.value,
      roleId: this.registerForm.get("roleId")?.value,
      countryCode: this.registerForm.get("countryCode")?.value,
      defaultLanguageCode: this.registerForm.get("defaultLanguageCode")?.value,
      shopCartProducts: this._shopCartService.getCartProductsIdsFromLocal()
    };

    this._userService.createUser(newUserRequest)
      .subscribe(this.registerNewUserObserver);
  }

  getCountryNameByLanguage(country: Country) {
    return country.country_general_info.find(cgi => cgi.language.languageIsoCode == this.translate.currentLang)?.name;
  }
}
