import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from 'src/environments/environment';
import { CookieTokenService } from '../auth/services/cookie-token.service';
import { Router } from '@angular/router';
import { SnackbarService } from '../shared/services/snackbar.service';

@Injectable()
export class CustomHttpRequestInterceptor implements HttpInterceptor {

  private sessionExpiredShown = false;

  constructor(
    private _cookieTokenService: CookieTokenService,
    private router: Router,
    private _snackbarService: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isAuthRequest = false;
    let newRequest = request;
    let urlComponents = request.url.split('/');
    let lastUrlComponent = urlComponents[urlComponents.length - 1];

    env.no_auth_required_routes.forEach((route) => {
      if (lastUrlComponent.includes(route)) isAuthRequest = true;
    });

    if (!isAuthRequest && this._cookieTokenService.isValidToken()) {
      let headersWithAuth = new HttpHeaders()
        .set('Authorization', `${env.token_type}${this._cookieTokenService.getCookieToken()}`);

      request.headers.keys().forEach((key) => {
        const value = request.headers.get(key) || '';
        headersWithAuth = headersWithAuth.append(key, value);
      });

      newRequest = request.clone({ headers: headersWithAuth });
    }

    if (!newRequest.headers.has('NO-CONTENT-TYPE')) {
      newRequest = newRequest.clone({
        headers: newRequest.headers
          .append('Content-type', 'application/json')
          .append('Accept', 'application/json')
      });
    } else {
      newRequest = newRequest.clone({
        headers: newRequest.headers
          .delete('NO-CONTENT-TYPE')
          .append('Accept', 'application/json')
      });
    }

    return next.handle(newRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (!this.sessionExpiredShown) {
            this.sessionExpiredShown = true;
            console.warn('⚠️ Sesión expirada. Mostrando mensaje y redirigiendo al login...');
          
            this._snackbarService.openSessionExpiredSnackBar();
          
            this._cookieTokenService.deleteCookieToken();
            this.router.navigate(['/login']).then(() => {
              setTimeout(() => this.sessionExpiredShown = false, 5000);
            });
          }
        }
        return throwError(() => error);
      })
    );
  }
}
