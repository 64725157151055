export const environment = {
  production: false,
  url_api: 'https://api.dev.i3book.softipal.com/api',
  images_url_base: 'https://dev.i3book.softipal.com/api/storage/coverimages/',
  desktop_app_url: 'https://dev.i3book.softipal.com/api/i3bookapp.exe',
  storage_img: 'storage/coverimages/',
  api_version: 'v1',
  token_type: 'Bearer ',
  no_auth_required_routes: ['login', 'twofa-verify', 'twofa-qr-code'],
  password_min_length: 8,
  session_duration_minutes: 120,
  code_length: 6,
  version: "2.1.7",
  languages_supported: ['es', 'us', 'pt'],
  default_language: 'es',
  payment: {
    payu: {
      webcheckout_url: 'https://checkout.payulatam.com/ppp-web-gateway-payu',
      description: 'I3book - PayU',
      default_tax: '0',
      default_taxReturnBase: '0',
      test: '0',
      response_url: 'https://i3book.net/#/payment/confirm-payu-payment',
      response_urlShop: 'https://shop.gridmark-club.com/#/payment/confirm-payu-payment',
      response_urlCol: 'https://colombia.shop.gridmark-club.com/#/payment/confirm-payu-payment',
      response_urlSpa: 'https://spain.shop.gridmark-club.com/#/payment/confirm-payu-payment',
      response_urlUsa: 'https://usa.shop.gridmark-club.com/#/payment/confirm-payu-payment',
      response_urlMex: 'https://mexico.shop.gridmark-club.com/#/payment/confirm-payu-payment',
      response_urlPgal: 'https://mexico.shop.gridmark-club.com/#/payment/confirm-payu-payment'
    },
    paypal: {
      base_payment_route: 'https://i3book.net/#',
      base_payment_routeShop: 'https://shop.gridmark-club.com/#',
      base_payment_routeCol: 'https://colombia.shop.gridmark-club.com/#',
      base_payment_routeSpa: 'https://spain.shop.gridmark-club.com/#',
      base_payment_routeUsa: 'https://usa.shop.gridmark-club.com/#',
      base_payment_routeMex: 'https://mexico.shop.gridmark-club.com/#',
      base_payment_routePgal: 'https://portugal.shop.gridmark-club.com/#'      
    }
  }
};
