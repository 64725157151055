import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { Document } from '../models/documents.model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private _httpClient: HttpClient) {}

  uploadBase64File(document: any) {

    return this._httpClient.post(`http://127.0.0.1:8000/api/v1/documents/document`, document, { observe: 'response' as 'response'})
  }

  upload2(answer: object): Observable<any> {


    return this._httpClient.post(`${env.url_api}/${env.api_version}/documents/document`, answer);
  }

  getAllDocuments() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/documents/docs`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  getAllDocumentsBylanguage(lang: string) {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/documents/docs/${lang}`, { observe: 'response' as 'response'})
    .pipe(
      catchError(error => {
        throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
      }),
      map((response: HttpResponse<any>) => {
      return ResponseHelper.generateCommonResponse(response);
    }));
  }

  deleteDocument(id: string): Observable<any> {
    return this._httpClient.delete(`${env.url_api}/${env.api_version}/documents/delete/${id}`).pipe(
        catchError((error) => {
            console.error('Error al eliminar el archivo', error);
            return throwError(error);
        }),
        map((response: any) => {
        
            return response;
        })
    );
  }

  createQuestions(question: any): Observable<any> {

    return this._httpClient.post(`${env.url_api}/${env.api_version}/documents/questions`, question);
  }

  getQuestions(): Observable<any> {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/documents/question`)
    .pipe(
      catchError(error => {
        console.error('Error al obtener preguntas', error);
        return throwError(error);
      }),
      map((response: any) => {
        return response;
      })
    );
  }

  deleteQuestion(id: number): Observable<any> {
    const url = `${env.url_api}/${env.api_version}/documents/questions/${id}`;
    return this._httpClient.delete(url).pipe(
        catchError((error) => {
            console.error('Error al eliminar la pregunta', error);
            return throwError(error);
        }),
        map((response: any) => {
            return response;
        })
    );
  }
}
