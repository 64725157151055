import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { PaypalPaymentService } from '../../services/paypal-payment.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { PayuPaymentService } from '../../services/payu-payment.service';
import { CountryService } from 'src/app/countries/services/country.service';
import { ShopProductsService } from 'src/app/books/services/shop-products.service';
import { GeneralPaymentService } from '../../services/general-payment.service';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  @Input() formData: any; // Recibir los datos del formulario
  userId: number;
  private redirection_to_books: string;
  ngOnInit(): void {
    this.getCountry();
    // Suscríbete a los cambios en el país
    this._countryService.countryName$.subscribe(country => {
      this.countryName = country;
    });
    const user = this._loginService.getLoggedUser();

    if (user != null) {
      this.userId = user.userId;
    }
  }

  readonly startPaypalPaymentObserver = {
    next: (data: CommonResponse<any>) => this.startPaypalPaymentNext(data),
    error: (error: CommonResponse<any>) => this.startPaypalPaymentError(error),
    complete: () => {
      this._pageLoadingService.hideLoadingGif();
    }
  };

  readonly startPayuPaymentObserver = {
    next: (data: CommonResponse<any>) => this.startPayuPaymentNext(data),
    error: (error: CommonResponse<any>) => this.startPayuPaymentError(error),
    complete: () => {
      this._pageLoadingService.hideLoadingGif();
    }
  };

  @Output() paymentProcessed = new EventEmitter<void>();
  countryName: string = '';
  countriesPaypal: string[] = ['España', 'Spain', 'Estados Unidos', 'United States', 'Mexico', 'Portugal'];

  constructor(private _paypalService: PaypalPaymentService,
    private _payuService: PayuPaymentService,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackbarService,
    private _loginService: LoginService,
    private _countryService: CountryService,
    private _shopProductsService: ShopProductsService,
    private _paymentService: GeneralPaymentService,
    private _userService: UserService,
  ) {
  }

  startPaypalPaymentNext(data: CommonResponse<any>) {
    //console.log(data);
    this.paymentProcessed.emit();

    //console.log('startPaypalPaymentNext');


    this._pageLoadingService.hideLoadingGif();

    let openSandbox = window.open(data.data.payment_redirect_link, '_blank', 'popup');
    const currentUrl = window.location.href;
    //console.log(currentUrl);

    const urlMappings: { [key: string]: string } = {
      'https://i3book.net/#/book/shop-cart': env.payment.paypal.base_payment_route,
      'https://shop.gridmark-club.com/#/book/shop-cart': env.payment.paypal.base_payment_routeShop,
      'https://colombia.shop.gridmark-club.com/#/book/shop-cart': env.payment.paypal.base_payment_routeCol,
      'https://spain.shop.gridmark-club.com/#/book/shop-cart': env.payment.paypal.base_payment_routeSpa,
      'https://usa.shop.gridmark-club.com/#/book/shop-cart': env.payment.paypal.base_payment_routeUsa,
    };

    const baseShopUrl = urlMappings[currentUrl];

    if (!baseShopUrl) {
      console.error('Unsupported URL');
      return;
    }
    this.redirection_to_books = `${baseShopUrl}/book/shop`;

    if (data.data.orderPayment !== null || '') {
      this._paymentService.stopProcessing();
      window.location.href = this.redirection_to_books;
    }

    let timer = setInterval(() => {
      if (openSandbox?.closed) {
        clearInterval(timer);
        this._paymentService.stopProcessing();
        window.location.href = this.redirection_to_books;
      }
    }, 1000);
  }

  startPaypalPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('paymentErrorOccurred');

    this._paymentService.stopProcessing();
  }

  startPayuPaymentNext(data: CommonResponse<any>) {

    this.paymentProcessed.emit();

    //console.log('startPayuPaymentNext');

    // this.paymentProcessed.emit();

    const currentUrl = window.location.href;
    const loggedUser = this._loginService.getLoggedUser();
    const form = document.createElement('form');
    form.method = 'post';
    form.action = env.payment.payu.webcheckout_url;

    const payuFormInputsNames = [
      'merchantId',
      'accountId',
      'description',
      'referenceCode',
      'amount',
      'tax',
      'taxReturnBase',
      'currency',
      'signature',
      'test',
      'buyerEmail',
      'responseUrl',
      'confirmationUrl'
    ];

    const inputType = 'hidden';
    let payuFormInputs: { [key: string]: HTMLInputElement } = {};

    for (let i of payuFormInputsNames) {
      let input = document.createElement('input');
      input.name = i;
      input.type = inputType;
      payuFormInputs[i] = input;
    }

    payuFormInputs['merchantId'].value = data.data.merchantId;
    payuFormInputs['accountId'].value = data.data.accountId;
    payuFormInputs['description'].value = env.payment.payu.description;
    payuFormInputs['referenceCode'].value = data.data.paymentInfo.paymentDetailsId;
    payuFormInputs['amount'].value = data.data.paymentInfo.amount;
    payuFormInputs['tax'].value = env.payment.payu.default_tax;
    payuFormInputs['taxReturnBase'].value = env.payment.payu.default_taxReturnBase;
    payuFormInputs['currency'].value = data.data.currency;
    payuFormInputs['signature'].value = data.data.signature;
    payuFormInputs['test'].value = env.payment.payu.test;
    payuFormInputs['responseUrl'].value = this.selectResponseUrl(currentUrl);
    payuFormInputs['confirmationUrl'].value = `${env.url_api}/${env.api_version}/payment/payu/confirm-payment`;
    //console.log(payuFormInputs);

    if (loggedUser !== null && loggedUser !== undefined) {
      payuFormInputs['buyerEmail'].value = loggedUser.email;
    }
    else {
      delete payuFormInputs['buyerEmail'];
    }

    Object.values(payuFormInputs).forEach(i => {
      form.appendChild(i);
    });

    document.getElementsByTagName('body')[0].appendChild(form);

    form.submit();
  }

  selectResponseUrl(currentUrl: string): string {
    const responseUrlMap: { [key: string]: string } = {
      'https://colombia.shop.gridmark-club.com/#/book/shop-cart': env.payment.payu.response_urlCol,
      'https://spain.shop.gridmark-club.com/#/book/shop-cart': env.payment.payu.response_urlSpa,
      'https://usa.shop.gridmark-club.com/#/book/shop-cart': env.payment.payu.response_urlUsa,
      'https://shop.gridmark-club.com/#/book/shop-cart': env.payment.payu.response_urlShop,
    };
    for (const urlPattern in responseUrlMap) {
      if (currentUrl.includes(urlPattern)) {
        //console.log(responseUrlMap[urlPattern]);
        return responseUrlMap[urlPattern];

      }
    }
    // Si no coincide con ningún patrón, devuelve la URL por defecto
    return env.payment.payu.response_url;
  }
  startPayuPaymentError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('paymentErrorOccurred');

    this._paymentService.stopProcessing();
  }

  storeShippingData(){
    this.formData.userId = this.userId;
    this._userService.userShippingInfo(this.formData).subscribe({
      next: (response) => {
        //console.log("Respuesta del servicio:", response);
      },
      error: (error) => {
        console.error("Error al almacenar shippingInfo:", error);
        this._snackbarService.openStandardSnackBar('storeShippingDataError');
      },
    });
  }

  async payByPaypal(): Promise<void> { 
    this._pageLoadingService.showLoadingGif();

    // Validar recursivamente si todos los valores en el objeto son válidos
    const isValid = this.validateFormData(this.formData);

    if (!isValid) {
      setTimeout(() => {
        this._snackbarService.openStandardSnackBar('questions_validate');
        this._pageLoadingService.hideLoadingGif();
      }, 1000);
      return; // Detiene la ejecución aquí si algún campo es inválido
    }
    await this.storeShippingData();
    
    this._paymentService.startProcessing();

    // Iniciar temporizador de redirección después de un tiempo determinado (por ejemplo, 2 minutos)
    const redirectTimeout = setTimeout(() => {
      this.redirectToShop();
    }, 30000); // 120000 ms = 2 minutos


    this._paypalService.startPayment()
      .subscribe({
        ...this.startPaypalPaymentObserver,
        complete: () => {
          clearTimeout(redirectTimeout); // Si el pago se completa, cancelar el temporizador
          this._pageLoadingService.hideLoadingGif();
          this._paymentService.stopProcessing();
        }
      });
  }

  async payByPayU(): Promise<void> {
    this._pageLoadingService.showLoadingGif();

    // Validar recursivamente si todos los valores en el objeto son válidos
    const isValid = this.validateFormData(this.formData);

    if (!isValid) {
      setTimeout(() => {
        this._snackbarService.openStandardSnackBar('questions_validate');
        this._pageLoadingService.hideLoadingGif();
      }, 1000);
      return; // Detiene la ejecución aquí si algún campo es inválido
    }
    await this.storeShippingData();

    this._paymentService.startProcessing();

    const redirectTimeout = setTimeout(() => {
      this.redirectToShop();
    }, 30000); // 120000 ms = 2 minutos

    this._payuService.startPayment()
      .subscribe({
        ...this.startPayuPaymentObserver,
        complete: () => {
          clearTimeout(redirectTimeout); // Si el pago se completa, cancelar el temporizador
          this._pageLoadingService.hideLoadingGif();
          this._paymentService.stopProcessing();
        }
      });
  }

  validateFormData(data: any): boolean {    
    for (const key in data) {
      if (typeof data[key] === 'object' && data[key] !== null) {
        // Validar recursivamente objetos internos
        if (!this.validateFormData(data[key])) {
          return false;
        }
      } else if (data[key] === null || data[key] === "") {
        // Si el valor es null o vacío, no es válido
        return false;
      }
    }
    // Todos los valores son válidos
    return true;
  }

  redirectToShop() {
    window.location.href = this.redirection_to_books;
  }

  getCountry() {
    this.countryName = this._countryService.getCountryName();

  }

  // Método para verificar si el país es uno de los países de PayPal
  isPaypalCountry(country: string): boolean {
    return this.countriesPaypal.includes(country);
  }

}
