import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private countryName: string;
  private endpointCountryGlobal = "countryGlobal";
  private endpointStateGlobal = "stateGlobal";
  private endpointCityGlobal = "cityGlobal";
  countryName$: Subject<string>;

  constructor(private _httpClient: HttpClient) {
    this.countryName$ = new Subject();
    this.countryName = localStorage.getItem('countryName') || '';
    if (this.countryName) {

      //console.log('countryName', this.countryName);
      

      this.countryName$.next(this.countryName);
    }
  }

  setCountryName(countryName: string) {
    this.countryName = countryName;
    localStorage.setItem('countryName', countryName);
    this.countryName$.next(this.countryName);
  }

  getCountryName(): string {
    return this.countryName;
  }

  getCountryName$(): Observable<string> {
    return this.countryName$.asObservable();
  }

  getCountryInfoByClientIp(languageCode: string) {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/country-by-ip/${languageCode}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getAllCountries() {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getCountryInfoByUrl(url: string, languageCode: string): Observable<any> {
    return this._httpClient.post<any>(`${env.url_api}/${env.api_version}/get-country-info`, { url, languageCode });
  }
  createCountryUser(request: { userId: number; countryCode: string }): Observable<any> {
    return this._httpClient.post<any>(`${env.url_api}/${env.api_version}/countries/users`, request);
  }
  updateCountryUser(request: any){
    //console.log(" SEVICE updateCountryUser: ", request);
    
    return this._httpClient.post<any>(`${env.url_api}/${env.api_version}/countries/users/update`, request);
  }

  getCountriesByUser(userId: number) {
    const url = `${env.url_api}/${env.api_version}/countries/users/getCountriesByUser/${userId}`;
  
    return this._httpClient.get(url, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        })
      );
  }

  getPersonTypeByCountryCode(countryCode: string) {
    //console.log(" SEVICE getPersonTypeByCountryCode");
    
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries/getPersonTypeByCountryCode/${countryCode}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getPersonTypesByCountryCodeLanguageCode(countryCode: string, languageCode: string): Observable<any> {
    return this._httpClient.post(`${env.url_api}/${env.api_version}/countries/getPersonTypeByCountryCodeLanguageCode`, {countryCode, languageCode}, {observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getIdentificationTypeByCountryCode(countryCode: string) {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries/getIdentificationTypeByCountryCode/${countryCode}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getIdentificationTypeByPersonTypeId(personTypeId: number): Observable<any> {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries/getIdentificationTypeByPersonTypeId/${personTypeId}`, {observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  
  getAllCountriesGlobal(): Observable<any> {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries/${this.endpointCountryGlobal}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));

    // this._httpClient.get('https://countriesnow.space/api/v0.1/countries/states').subscribe((data: any) => {
    //   var countries = data.data;
    // });
  }


  getAllCountriesGlobalByLanguageCode(languageCode: string): Observable<any> {
    return this._httpClient.get(`${env.url_api}/${env.api_version}/countries/${this.endpointCountryGlobal}/${languageCode}`, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getStatesGlobalByCountryIdLanguageCode(countryId: number, languageCode: string): Observable<any> {
    return this._httpClient.post(`${env.url_api}/${env.api_version}/countries/${this.endpointStateGlobal}`, {countryId, languageCode}, {observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getCitiesGlobalByStateIdLanguageCode(stateId: number, languageCode: string): Observable<any> {
    return this._httpClient.post(`${env.url_api}/${env.api_version}/countries/${this.endpointCityGlobal}`, {stateId, languageCode}, {observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

  getAllCities(token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this._httpClient.get(`${env.url_api}/${this.endpointCityGlobal}`, { headers: headers });
  }

  getAllCitiesByStateId(token: string, stateId: number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this._httpClient.get(`${env.url_api}/${this.endpointCityGlobal}/search?column=stateId&key=${stateId}&exact=true`, { headers: headers });
  }


}
