import { Component, OnInit, Renderer2, RendererStyleFlags2,ViewChild,ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { EditorialService } from 'src/app/editorials/services/editorial.service';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { environment as env } from 'src/environments/environment';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { ViewMyBooksProduct } from '../../models/view-my-books-book.model';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CountryService } from 'src/app/countries/services/country.service';
import { UserService } from 'src/app/users/services/user.service';
import { Observable, switchMap, tap, EMPTY } from 'rxjs';
// import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-view-my-books-component',
  templateUrl: './view-my-books-component.component.html',
  styleUrls: ['./view-my-books-component.component.scss']
})

export class ViewMyBooksComponentComponent implements OnInit {

  isFilterVisible = false;  // Estado para controlar la visibilidad del input
  filterValue = ''; // valor del campo del filtro
  isFilterActive = false; // Indica si el filtro esta activo

  displayedColumns: string[] = ['imgName', 'productCode', 'id', 'code', 'title', 'editoriaBook', 'resume', 'multilanguageText', 'docLink'];
  // displayedColumns: string[] = ['imgName', 'productCode', 'id', 'code', 'title', 'editoriaBook', 'resume', 'multilanguageText'];
  dataSource = new MatTableDataSource<ViewMyBooksProduct>();
  api_path_img = `${env['images_url_base']}`;

  idUser = 0;
  permissions: string[];
  isAdmin = false;
  userCountries: any[] = [];
  user: any;

  filteredEditorials!:MatTableDataSource<ViewMyBooksProduct>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>;


  private readonly getUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.getUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.getUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };


  private readonly deleteUserProductsObserver = {
    next: (data: CommonResponse<any>) => this.deleteUserProductsNext(data),
    error: (error: CommonResponse<any>) => this.deleteUserProductsError(error),
    complete: () => this._pageLoadingService.hideLoadingGif()
  };

  constructor(private _editorialService: EditorialService,
    private _loginService: LoginService,
    private _pageLoadingService: PageLoadingService,
    private _translate: TranslateService,
    private _snackbarService: SnackbarService,
    private _dialog: MatDialog,
    private _countryService: CountryService,
    private _userService: UserService,
    private _renderer: Renderer2) {}

    // ngOnInitOld(): void {
    //   this._pageLoadingService.showLoadingGif();
    //   const user = this._loginService.getLoggedUser();
    //   if (user != null) {
    //     this.idUser = user.userId;
    //     this.permissions = user.permissions;
    //     this.havePermissions(this.permissions);
    
    //     let userId = user.userId;
    //     this.getUser();

    //     // si el usuario es super admin obtener todos los productos
    //     if(userId == 2) {
    //       this.getproducts();
    //     } else { // si el usuario NO es super admin obtener los productos del país que el usuario pertenece
    //     // Obtener los países del usuario
    //     this._countryService.getCountriesByUser(userId).subscribe((response: any) => {
    //       if (response.statusCode === 200) {
    //         this.userCountries = response.data;
    //         // Llama a getproducts() después de obtener los países
    //         this.getFilteredProducts();
    //       }
    //     });
    //     }
    //   }
    // }

  ngOnInit(): void {
    this.initPageData();
  }
  
  async initPageData(): Promise<void> {
    try {
      this._pageLoadingService.showLoadingGif();
      const user = this._loginService.getLoggedUser();
  
      if (user != null) {
        this.idUser = user.userId;
        this.permissions = user.permissions;
        this.havePermissions(this.permissions);
        let userId = user.userId;
  
        // Espera a que se complete getUser()
        await this.getUser();

        
        const hasRolePencilOwner =user.roles.includes('pencil_owner'); 
        const hasRoleEditorialAdmin =user.roles.includes('editorial_admin'); 
        const hasRoleCountry_admin =user.roles.includes('country_admin'); 

        
        // Si tiene role super admin, traer todos los productos de todos los paises
        if (hasRoleEditorialAdmin) {
          // Se cargan todos los productos segun el usuario, el backend se encarga de hacer la discriminación de los productos a retornar. 
          await this.getproducts();
        } else if(hasRoleCountry_admin){           
          const countriesResponse = await this._countryService.getCountriesByUser(userId).toPromise();
          if (countriesResponse && countriesResponse.statusCode === 200) {
            this.userCountries = countriesResponse.data as any[];
            // Llama a getFilteredProducts() después de obtener los países
            await this.getFilteredProducts();
            //await this.getproducts();
          }
        }
        else if(hasRolePencilOwner) // Se deja como última prioridad al rol de usuario general. 
        {
          await this.getproducts(); 
          //await this.getFilteredProducts();
        }
      }
    } catch (error) {
      this._pageLoadingService.hideLoadingGif();

      console.error("Error en initPageData: ", error);
    }
  }
    
    async getFilteredProducts(): Promise<void> {
      this._editorialService.getproducts(2, 1, this.idUser).subscribe((data: CommonResponse<any>) => {
        if (ResponseHelper.responseDontHaveErrors(data)) {
          const products = (data.data as CommonResponse<any>).data;
          // Extraer los códigos de país del usuario
          const userCountryCodes = this.userCountries.map(country => country.countryCode);
    
          // Filtrar productos según los códigos de país
          const validUserCountryCodes = userCountryCodes || [];
          //console.log('datos antes de filtrar:--->',products);
          const filteredProducts = products.filter((product: { prices?: any[] }) =>
            Array.isArray(product.prices) &&
            product.prices.some(price => validUserCountryCodes.includes(price.country?.countryCode))
          );
    
          // Asignar los productos filtrados al dataSource
          this.dataSource.data = filteredProducts;
    
          setTimeout(() => {
            this.initializePaginator();
            this._pageLoadingService.hideLoadingGif();
          }, 500);
        } else {
          this._pageLoadingService.hideLoadingGif();
        }
      });
    }

  getUserOld() {
    this._userService.getUser(this.idUser).subscribe((response: any) => {
      if (response.statusCode === 200) {
        this.user = response.data;
      }
    });
  }

  async getUser(): Promise<void> {
    try {
      const response = await this._userService.getUser(this.idUser).toPromise();
      if (response && response.statusCode === 200) {
        this.user = response.data;
      }
    } catch (error) {
      console.error("Error en getUser: ", error);
      throw error; // Propaga el error para manejarlo en initPageData
    }
  }  

  filterCountries(): void {
    if (!this.userCountries.length || !this.dataSource.data.length) {
      return; // Asegúrate de que hay datos antes de proceder
    }
  
    // Extraer los códigos de país del usuario
    const userCountryCodes = this.userCountries.map(country => country.countryCode);
  
    // Filtrar los productos según los códigos de país
    const filteredProducts = this.dataSource.data.filter(product =>
      product.prices.some(price => userCountryCodes.includes(price.country.countryCode))
    );
  
    // Actualizar el dataSource con los productos filtrados
    this.dataSource.data = filteredProducts;
  
    // Reiniciar el paginador para reflejar los datos filtrados
    this.initializePaginator();
  }
  

  showFilterInput(): void {
    setTimeout(() => {
      this.filterInput.nativeElement.focus(); // Establece el foco en el input
    }, 0);
    this.isFilterVisible = true;

    //valor a setear en el campo del filtro
  }

  hideFilterInput(): void {
    this.isFilterVisible = false;
  }

  applyFilter(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const filterValue = inputElement.value.trim().toLowerCase();
  
    //console.log('Aplicando el filtro...', filterValue);
      // Aplica la lógica de filtrado y actualiza el estado del filtro
    this.isFilterActive = this.filterValue.length > 0;
  
    // Configura el filterPredicate para el dataSource existente
    this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
      const companyName = data.editorialBookName || ''; // Nombre de la editorial
      return companyName.toLowerCase().includes(filter); // Verifica si el filtro coincide
    };
  
    // Asigna el valor del filtro al dataSource
    this.dataSource.filter = filterValue;
  
    //console.log('Datos filtrados:', this.dataSource.filteredData);
  
    // // Opcional: Oculta el campo de filtro
    // this.hideFilterInput();
  }


  getUserProductsNext(data: CommonResponse<any>) {
    if (ResponseHelper.responseDontHaveErrors(data)) {
      const products = (data.data as CommonResponse<any>).data;
      //console.log('datos todos:',products);
      // Agrupar productos por `id` y calcular la cantidad
      const groupedProducts = products.reduce((acc: any[], product: { id: any; }) => {
        const existingProduct = acc.find((item: any) => item.id === product.id);
        if (existingProduct) {
          existingProduct.quantity++;
        } else {
          acc.push({ ...product, quantity: 1 });
        }
        return acc;
      }, []);
  
      this.dataSource.data = groupedProducts;
  
      setTimeout(() => {
        this.initializePaginator();
        this._pageLoadingService.hideLoadingGif();
      }, 500);
    } else {
      this._pageLoadingService.hideLoadingGif();
    }
  }
  

  getUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this.dataSource.data = [];
      this._snackbarService.openStandardSnackBar('getUserProductsError404');
    }
    // else {
    //   this._snackbarService.openStandardSnackBar('getUserProductsError');
    // }
  }

  getproducts() {
    this._editorialService.getproducts(2, 1, this.idUser).subscribe(this.getUserProductsObserver);
  }

  initializePaginator() {
    this.dataSource.paginator = this.paginator;
  }

  havePermissions(permissions: any) {
    const havePermissions = permissions.find(function (permission: any) {
      return permission === 'update_book';
    });

    if (havePermissions == 'update_book') {
      this.isAdmin = true;
      this.displayedColumns.push('availability');
      this.displayedColumns.push('actions');
      //console.log('entra a if');
    }else {
      this.displayedColumns.push('quantity');
      //console.log('entro a else--->',this.displayedColumns);
    }
  }

  getBookTitleByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.id == id);
    if (product != null && product != undefined) {
      return product.product_general_info[0]?.title ?? "empty title";
    } else {
      return "empty title";
    }
  }
  

  getEditorialBook(id: number) {
    const product = this.dataSource.data.find(x => x.id == id);
    //console.log('producto   --->',product);
    if (product?.editorialBookId !== null && product?.editorialBookId !== undefined) {
      //console.log('si tiene editorial',product.editorialBookName);
      return product.editorialBookName;
    }
    else {
      //console.log('no tiene editorial',product);
      return "empty editorial";
    }
  }

  getBookResumeByLanguage(id: number) {
    const product = this.dataSource.data.find(x => x.id == id);
  
    if (product != null && product != undefined) {
      return product.product_general_info[0]?.resume ?? "empty resume";
    } else {
      return "empty resume";
    }
  }
  

  getMultiLanguageText(id: number): string[] {
    const bookLanguages: string[] = [];
    const product = this.dataSource.data.find(x => x.id === id);

    if (product != null && product != undefined) {
      const translations: { [key: string]: { [key: string]: string } } = {
        en: {
          en: "English",
          es: "Spanish",
          pt: "Portuguese"
        },
        es: {
          en: "Inglés",
          es: "Español",
          pt: "Portugués"
        },
        pt: {
          en: "Inglês",
          es: "Espanhol",
          pt: "Português"
        }
      };

      const currentLang = this._translate.currentLang;

      product.product_general_info.forEach(bc => {
        const isoCode = bc.language.languageIsoCode;

        const langName = translations[currentLang]?.[isoCode] || ";";
        bookLanguages.push(langName);
      });
    }

    return bookLanguages;
  }


  deleteUserProductsNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    this._snackbarService.openStandardSnackBar('successfullyDeletedProduct','ok');
    this._editorialService.getproducts(2, 1, this.idUser).subscribe(this.getUserProductsObserver);
  }

  deleteUserProductsError(error: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();

    if (error.statusCode == 404) {
      this._snackbarService.openStandardSnackBar('unsuccessfullyDeletedProduct');
    }
    else {
      this._snackbarService.openStandardSnackBar('errorDeletingProduct');
    }
  }

  deleteProduct(id: number) {
    const data: DialogData = {
      title: this._translate.instant('delete_product.title'),
      message: this._translate.instant('delete_product.confirmation_question'),
      userInteractResult: false
    };

    const confirmPurchaseDialogRef = this._dialog.open(ConfirmationModalComponent, {
      width: '250px',
      data: data
    });

    const dialogContainer = document.querySelector('mat-dialog-container');
    if (dialogContainer) {
      this._renderer.setStyle(dialogContainer, 'border-top', 'solid 5px orangered', RendererStyleFlags2.Important);
    }

    confirmPurchaseDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._pageLoadingService.showLoadingGif();
        this._editorialService.deleteProduct(id).subscribe(this.deleteUserProductsObserver);
      }
      else {
        // console.log('Compra no confirmada');
      }
    });
  }

  getDownloadUrl(filePath: string): string {
    const normalizedPath = filePath.replace(/\\/g, '/'); // Normalizar barras invertidas
  
    // Extraer el nombre del archivo sin extensión
    const fileNameWithExt = normalizedPath.split('/').pop(); // "PRUEBA PARA LAPICES MANUAL_es_V1.pdf"
    if (!fileNameWithExt) return ''; // Si no hay archivo, retornar vacío
  
    // Eliminar la extensión .pdf y dividir por "_"
    const parts = fileNameWithExt.replace('.pdf', '').split('_'); // ["PRUEBA PARA LAPICES MANUAL", "es", "V1"]
    if (parts.length < 3) return ''; // Si no tiene suficiente información, retornar vacío
  
    const version = parts.pop(); // "V1"
    const fileName = parts.join('_'); // "PRUEBA PARA LAPICES MANUAL"
  
    // Obtener el idioma actual desde el servicio de traducción
    const currentLanguage = this._translate.currentLang;
  
    return `${env.url_api}/${env.api_version}/documents/download/${fileName}/${version}/${currentLanguage}`;
  }
  
  
}
