import { HttpResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { CookieTokenService } from './cookie-token.service';
import { catchError, map } from 'rxjs/operators';
import { LoggedUser, UserAuthBase, UserAuthLogin } from '../models/user.auth';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { LocalShopCartProduct } from 'src/app/books/models/local-shopcart-product.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private static sessionTimeout: NodeJS.Timeout;
  private readonly USER_SESSION_KEY = "logged_user";
  private readonly _localStorageCartKey = 'i3bookapp_shop_cart';

   /*
    Session timeout is a timeout configured for expires the user session.
    At this.login, this timeout is created with a subscription to logout when
    the timeout completes.
    When the user session expires cookie and session data are deleted and the
    user is rederected to login view.
  */
    // private static sessionTimeout: NodeJS.Timeout;
    private readonly sessionTimeoutLogoutObserver = {
      next: (data: any) => {
        this._snackbarService.openStandardSnackBar('session_expired','ok');

        this._router.navigate(['/login']);
      },
      error: (error: any) => {}
    }

  constructor(private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService,
    private _snackbarService: SnackbarService,
    private _router: Router) {
  }

  getLoggedUser(): LoggedUser | null {
    const user = sessionStorage.getItem(this.USER_SESSION_KEY);

    if(user) {
      return JSON.parse(user);
    }

    return null;
  }

  isAdmin(): boolean {
    const user = this.getLoggedUser();
    return user ? user.roles.includes('editorial_admin') : false;
  }

  /**
   * Update the session storage user information.
   * @param {LoggedUser} user : Logged user information
   */
  updateSessionUser(user: LoggedUser) {
    sessionStorage.removeItem(this.USER_SESSION_KEY);
    sessionStorage.setItem(this.USER_SESSION_KEY, JSON.stringify(user));
  }

  isLogged() {
    return this._cookieTokenService.isValidToken() &&
      sessionStorage.getItem(this.USER_SESSION_KEY);
  }

  twoFaLogin(email:string, password:string, code:string, cartProductsId: LocalShopCartProduct[] | null) {

    const request : UserAuthLogin = {
      "email": email,
      "password": password,
      "code": code,
      "shopCartProducts": cartProductsId
    };

    return this._httpClient.post(`${env.url_api}/${env.api_version}/auth/login`, request, { observe: 'response' as 'response'})
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          localStorage.setItem(this._localStorageCartKey, '[]');

          const responseBody = response.body;

          if(ResponseHelper.responseDontHaveErrors(responseBody)) {
            let cookieExpirationDate = new Date();
            cookieExpirationDate.setMinutes(cookieExpirationDate.getMinutes() + env.session_duration_minutes);

            this.updateSessionUser(responseBody.data.user);

            // sessionStorage.setItem(this.USER_SESSION_KEY, JSON.stringify(responseBody.data.user));
            this._cookieTokenService.setCookieToken(responseBody.data.token);
          }

          return ResponseHelper.generateCommonResponse(response);
      }));
  }

  getRecoveryCodes() {

    return this._httpClient.get(`${env.url_api}/${env.api_version}/auth/twofa-recovery-codes`, { observe: 'response' as 'response'})
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  getQrCodeAsSvg(email:string, password:string) {

    const request: UserAuthBase = {
      email: email,
      password: password
    };

    return this._httpClient.post(`${env.url_api}/${env.api_version}/auth/twofa-qr-code`, request, { observe: 'response' as 'response'})
      .pipe(map((response: HttpResponse<any>) => {
        return ResponseHelper.generateCommonResponse(response);
      }));
  }

  logout() {

    return this._httpClient.post(`${env.url_api}/${env.api_version}/auth/logout`, null, { observe: 'response' as 'response' })
      .pipe(map((response: HttpResponse<any>) => {
        const responseBody = response.body;

        if (response.status == 204 || response.status == 401) {
          this._snackbarService.openStandardSnackBar('session_expired','ok');
          this._cookieTokenService.deleteCookieToken();
          this._router.navigate(['/login']);
          sessionStorage.removeItem(this.USER_SESSION_KEY);
        }

        return responseBody;
      }));
  }
}
