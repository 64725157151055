import { HttpClient, HttpResponse,HttpParams  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, Observable } from 'rxjs';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { CommonResponse } from 'src/app/shared/models/reponse.model';
import { environment as env } from 'src/environments/environment';
import { PaymentDetails } from '../models/payment.model';



@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private _httpClient: HttpClient) {}

  getPaymentHistory(countries:string[]): Observable<CommonResponse<PaymentDetails[]>> {
    // Construir los parámetros de consulta

    const countryCodes = countries.join(','); // Convierte el array en un string separado por comas
    const params = new HttpParams().set('countries', countryCodes);
    //console.log('params a enviar:---->',`${countryCodes}`);
    return this._httpClient.get<CommonResponse<PaymentDetails[]>>(`${env.url_api}/${env.api_version}/users/getPayment-History`,
      { params } // Adjuntar los parámetros de consulta
    );
  }

  getPaymentProducts(paymentId: number, languajeCode: string) {

    return this._httpClient.get(`${env.url_api}/${env.api_version}/users/payment-products/${paymentId}/${languajeCode}`, {observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {
          return ResponseHelper.generateCommonResponse(response);
        }));
  }

}